<template>
  <div class="mt-4">
    <v-tooltip
      :disabled="map.coordsLonLat[0] != undefined"
      top
    >
      <template v-slot:activator="{ on }">
        <v-row
          class="ma-0"
          v-on="on"
        >
          <v-col class="pt-0 pl-0">
            <v-btn
              v-if="isTable"
              small
              block
              color="#0da344"
              style="color:white"
              :disabled="map.coordsLonLat[0] == undefined || !tableChartRangeDate"
              @click="openRasterLayerTablePopup()"
            >
              {{ $t('Marker.tab') }}
            </v-btn>
          </v-col>
          <v-col class="pt-0 pr-0">
            <v-btn
              v-if="isGraph"
              small
              block
              outlined
              color="#0da344"
              :disabled="map.coordsLonLat[0] == undefined || !tableChartRangeDate"
              @click="openRasterLayerGraphPopup()"
            >
              {{ $t('Marker.graph') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <span>{{ $t('ServiceCard.tableAlert') }}</span>
    </v-tooltip>
    <raster-layer-popup
      v-if="renderDialog"
      :is-animation="isAnimation"
      :raster-layers="rasterLayers"
      :selected-raster-layer="selectedRasterLayer"
      :min-step="minStep"
      :max-step="maxStep"
      :table-chart-range-date="tableChartRangeDate"
      :is-daily-data="isDailyData"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { getFormattedDate } from '@/utils.js';

export default {
  components: {
    RasterLayerPopup: () => import('@/components/utils/RasterLayerPopup')
  },
  props: {
    isTable: {
      type: Boolean,
      default: false
    },
    isGraph: {
      type: Boolean,
      default: false
    },
    isAnimation: {
      type: Boolean,
      default: false
    },
    minStep: {
      type: Date,
      default: null
    },
    maxStep: {
      type: Date,
      default: null
    },
    selectedRasterLayer: {
      type: Object,
      default: null
    },
    rasterLayers: {
      type: Array,
      default: () => []
    },
    serviceName: {
      type: String,
      default: ''
    },
    tableChartRangeDate: {
      type: String,
      default: ''
    },
    isDailyData: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      renderDialog: false,
      showTooltip: false

    };
  },
  computed: {
    ...mapState(['map', 'table_chart', 'app_user'])
  },
  watch: {
    $route() {}
  },
  mounted() {
  },
  methods: {
    ...mapActions([]),
    openRasterLayerTablePopup() {
      if (this.map.coordsLonLat[0] != undefined) {
        this.renderDialog = false;
        this.$nextTick(() => {
          this.renderDialog = true;
        });

        this.table_chart.isTable = true;
        this.table_chart.openRasterLayerTableChartPopup = true;
      }

      this.$gtag.event('Tabella', {
        event_category: `${this.$route.params.id == undefined ? 'Home' : this.$route.params.id}`,
        event_label: `${Date.now()} - ${this.app_user.username} - ${this.app_user.crm_id} - ${getFormattedDate(new Date(), true, true)}`,
        value: `Tabella ${this.serviceName}`
      });
    },
    openRasterLayerGraphPopup() {
      if (this.map.coordsLonLat[0] != undefined) {
        this.renderDialog = false;
        this.$nextTick(() => {
          this.renderDialog = true;
        });

        this.table_chart.isTable = false;
        this.table_chart.openRasterLayerTableChartPopup = true;
      }

      this.$gtag.event('Grafico', {
        event_category: `${this.$route.params.id == undefined ? 'Home' : this.$route.params.id}`,
        event_label: `${Date.now()} - ${this.app_user.username} - ${this.app_user.crm_id} - ${getFormattedDate(new Date(), true, true)}`,
        value: `Grafico ${this.serviceName}`
      });
    }
  }
};
</script>
